@import "src/constants";

.container {
  display: flex;
  flex-direction: column;
  font: normal normal 18px/18px "Roboto", sans-serif;
  padding: 8px 25px 8px 41px;
  background-color: $commonBackground !important;
  font-family: 'Open Sans', Arial, sans-serif;
  font-size: 20px;
  font-weight: 300;
  line-height: 28px;
  letter-spacing: 0;
  text-align: left;

  .linkContainer {
    padding: 0 0 0 0;
    margin: 16px 0 0 0;
  }

  a {
    font-family: 'Open Sans', Arial, sans-serif;
    font-size: 20px;
    font-weight: 300;
    line-height: 28px;
    letter-spacing: 0;
    text-align: left;
    color: #F57B20;
    text-decoration: none;
  }

  .liIcon {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      width: 30px !important;
      height: 30px !important
    }
    margin-right: 40px;
  }

  li {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    font-family: 'Open Sans', Arial, sans-serif;
    font-size: 20px;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;
  }

  .header {
    margin: 40px 0 60px 0;
    font-family: 'BebasNeue', Arial, sans-serif;
    font-size: 30px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
    padding-left: 41px;
    color: #000000;
  }

  .subHeader {
    margin: 40px 0;
    font-family: 'Open Sans', Arial, sans-serif;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
    padding-left: 41px;
    color: #000000;

    &:first-of-type {
      margin: 0 0 40px 0 !important;
    }
  }

  .linkAbout {
    padding: 48px 41px 16px 42px !important;
  }

  .about {
    background-color: #FFFFFF;
    padding: 48px 41px 41px 42px;
    margin-left: 41px;
    font-family: 'Open Sans', Arial, sans-serif;
    font-size: 20px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;

    &:last-of-type {
      margin: 40px 0 40px 41px;
      padding-top: 30px;
    }
  }

  .aboutCenter {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
  }

  .videoContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;


    .tabsContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 16px;
      padding: 0;
      margin: 0;

      .listElement {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-family: 'Open Sans', Arial, sans-serif;
        font-size: 18px;
        font-weight: 300;
        line-height: 18px;
        letter-spacing: 0;
        text-align: center;
        width: 20%;
        cursor: pointer;
        padding: 4px;
        border-bottom: 5px solid transparent;

        &:hover {
          color: $correctThemeColor;
        }

      }

      .listElementActive {
        border-bottom: 5px solid $correctThemeColor;
      }
    }
  }

  .aboutLessPadding {
    padding: 48px 41px 20px 32px !important;
  }

  .center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .withoutTopBottomPadding {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .withoutTop {
    padding-top: 0 !important;
    margin-top: 0 !important;
  }

  .margin63 {
    margin-bottom: 62px;
  }

  .margin20 {
    margin-bottom: 20px;
  }

  .list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #FFFFFF;

    .divider {
      width: 1px;
      background-color: #E2E4E4;
      height: 32px;
      margin-left: 15px;
    }

    .row {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-top: 4px;
      margin-bottom: 4px;

      .leftItemFirst {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }

      .rightItem {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-self: baseline;

        font-family: 'Open Sans', Arial, sans-serif;
        font-size: 20px;
        font-weight: 300;
        line-height: 28px;
        letter-spacing: 0;
        text-align: left;

        .bottomItems {
          margin-top: 16px;
        }

      }
    }

    .row:first-child {
      margin-bottom: 0;
      margin-top: 0;
    }

    .row:last-child {
      align-items: center;
    }

  }

  .photoRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 94px;
    overflow-x: auto;

  }
}

.checkIcon {
  width: 35px;
  height: 35px;
}

.aboutText {
  width: 100%;
  text-align: left;
}

.tariffsContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  zoom: 0.8;

  %tariffColumn {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: auto;

    .columnHeader {
      width: 400px;
      height: 30px;
      color: black;
      font-weight: bold;
      background-color: #f9fafc;
      border: 1px solid #c0c0c0;
      padding: 15px 0;
      align-items: center;
      text-align: center;
      font-family: 'Open Sans', Arial, sans-serif;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0;
    }

    .columnRow {
      width: 400px;
      border: 1px solid #c0c0c0;
      font-family: 'Open Sans', Arial, sans-serif;
      font-size: 20px;
      font-weight: 300;
      line-height: 28px;
      letter-spacing: 0;
      height: 30px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-align: center;
      padding: 8px 0;

      span {
        width: 55%;
        text-align: right;
      }

    }

    .columnRowCenter {
      width: 400px;
      border: 1px solid #c0c0c0;
      font-family: 'Open Sans', Arial, sans-serif;
      font-size: 20px;
      font-weight: 300;
      line-height: 28px;
      letter-spacing: 0;
      height: 30px;
      align-items: center;
      text-align: center;
      padding: 8px 0;
    }

    .columnRowSub {
      border: 1px solid #c0c0c0;
      padding: 15px;
      font-size: 15px;
      height: 48px;
      padding-left: 30px !important;
    }

  }

  .column {
    @extend %tariffColumn;
  }

  .editorRightPart {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    overflow-x: auto;

    &Item {
      @extend %tariffColumn;

      .columnRow {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 5px;
        height: 48px;
      }
    }
  }

}