@import "src/constants";

.pageContainer {
  width: calc(100% - 12px);
  height: calc(100% - 10px);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 5px 6px 5px 6px;
  user-select: none;

  .leftPart {
    display: flex;
    width: calc(100% - 240px);
    height: 100%;
    gap: 8px;
    user-select: none;

    .rowItemContainer {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      overflow-y: auto;
      background-color: #FFFFFF;

      :first-child {
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          margin-right: 8px;
        }
      }

      .documentsContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100% !important;
        overflow: auto;
      }

      .centering {
        align-items: center !important;
      }

    }
  }

  .rightPart {
    width: 230px;
    height: calc(100% - 70px);
    display: flex;
    flex-direction: column;
    user-select: none;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      -ms-hyphens: auto
    }

    .commentsEmptyContainer {
      justify-content: center !important;
      align-items: center !important;
    }

    .buttonsContainer {
      display: flex;
      flex-direction: column;
      gap: 22px;
      align-items: center;
      margin-top: 23px;
      width: 100% !important;
      zoom: 0.7;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        zoom: 1;
      }

      &Left {
        width: 100% !important;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 22px;
      }

      &Right {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;
      }

      .materialButton {

        width: 100% !important;
        height: 50px !important;
        border-radius: 5px;
        font-size: 16px;
        padding: 0 !important;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          margin-bottom: 22px !important;
          font-size: 12px;
          height: 35px !important;
        }
      }

      .materialButtonDelete {
        height: 50px !important;
        width: 150px !important;
        border-radius: 5px;
        border: 2px solid #213f95;
        display: flex;
        justify-content: center;
        color: #000 !important;
        font-size: 16px;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          margin-top: 22px !important;
          height: 35px !important;
          font-size: 12px;
        }
      }

      .deleteIcon {
        background: no-repeat url("bin.svg");
        width: 28px !important;
        height: 28px !important;
        margin: 0 6px 0 0 !important;
        padding-left: 0px;
        cursor: pointer;
        border-radius: 0 !important;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
         zoom: 0.7;
        }
      }

      .stopIcon {
        background: no-repeat url("stop2.png");
        width: 12px !important;
        height: 18px !important;
        margin: 0 8px 0 0 !important;
        cursor: pointer;
        border-radius: 0 !important;
        background-size: contain;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          zoom: 0.85
        }
      }

      .helpIcon {
        background: no-repeat url("help.svg");
        width: 20px !important;
        height: 20px !important;
        margin: 0 0 0 0 !important;
        padding: 0 4px;
        cursor: pointer;
        border-radius: 0 !important;
        background-size: contain;
      }

      .checkIcon {
        background: no-repeat url("check.png");
        width: 20px !important;
        height: 20px !important;
        margin: 0 8px 0 0 !important;
        cursor: pointer;
        border-radius: 0 !important;
        background-size: contain;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          zoom: 0.7
        }
      }

      .crossIcon {
        background: no-repeat url("close.png");
        width: 20px !important;
        height: 20px !important;
        margin: 0 8px 0 0 !important;
        cursor: pointer;
        border-radius: 0 !important;
        background-size: contain;
        zoom: 0.9;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
         zoom: 0.7
        }
      }
    }

    .commentsContainer {
      width: 100%;
      height: calc(100% - 20px);
      ms-height: 100% !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      background-color: $commonBackground;
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        margin-top: 20px !important
      }

      .subContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2px;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          margin: 20px 2px 2px 2px !important;
          margin-top: 20px !important
        }
      }

      &Item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          //display: table-row;
          //height: auto;
          margin-bottom: 6px !important;
          max-width: 250px !important;
        }
        width: calc(100% - 7px) !important;
        cursor: pointer;
        background-color: $commonBackground;
        border-radius: 0 !important;
        padding: 3.5px;

        &WrapperEmpty {
          justify-content: center !important;
          align-items: center !important;
        }

        &Wrapper {
          margin: 8px 0px 8px 8px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          gap: 8px;
          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            //display: table-cell;
            margin: 0;
          }


          &ItemEnpty {
            width: 100%;
            padding: 4px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            font-family: "Lato", "SansSerif", sans-serif;
            font-size: 20px;
            font-weight: 300;
            line-height: 28px;
            letter-spacing: 0em;
            text-align: left;
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
              display: inline-block;
            }
          }

          &Item {
            width: 100%;
            padding: 4px;
            display: flex;
            flex-direction: row;
            font-family: "Lato", "SansSerif", sans-serif;
            font-size: 20px;
            font-weight: 300;
            letter-spacing: 0em;
            height: 100%;
            text-align: left;

            span {
              font-size: 15px;
            }

            .text {
              font-size: 15px;
              line-height: 15px;
              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                margin-bottom: 4px;
                hyphens: auto;
                max-width: 150px;
              }
            }


            &Favourite {
              background: pink;
            }
          }

          &ItemClose {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
              display: block;
              align: right;

              .iconButton {
                display:inline-block
              }
            }
          }

        }
      }
      &Item:hover{
        border: 1px solid $selectedDiffColor;
        padding: 2.5px;
      }
    }

  }

}

.title {
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
  background-color: $commonBackground;
  text-align: center;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    height: 100% !important
  }
}

.selectedItem {
  padding: 0px 0px 0px 0.5px !important;
  border: 3.5px solid $selectedDiffColor !important;
  width: calc(100% - 7px) !important;
  ms-width: 100% !important;
  height: 34px !important;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    height: auto;
  }
}

.hidden {
  display: none !important;
}

.emptyComment {
  background-color: transparent !important;
  justify-content: center !important;
}

.documentButtonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 22px;
  margin-bottom: 6px;
  margin-top: 6px;
}

.commentsContainerItemCollapsed {
  min-height: auto !important;
  background: #c0ffc9 !important;
}

.rotateLeftIcon {
  @extend %rotateLeftIcon;
  width: 25px !important;
  height: 25px !important;
  background-size: contain;
  margin: 0 0 0 0 !important;
  padding: 0 !important;
  cursor: pointer;
  border-radius: 0 !important;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    margin-right: 22px !important
  }
}

.rotateRightIcon {
  @extend %rotateRightIcon;
  width: 25px !important;
  height: 25px !important;
  background-size: contain;
  margin: 0 0 0 0 !important;
  padding: 0 !important;
  cursor: pointer;
  border-radius: 0 !important;
}

.zoomInIcon {
  @extend %zoomInIcon;
  width: 25px !important;
  height: 25px !important;
  background-size: contain;
  margin: 0 0 0 0 !important;
  padding: 0 !important;
  cursor: pointer;
  border-radius: 0 !important;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    margin-right: 22px !important
  }
}

.zoomOutIcon {
  @extend %zoomOutIcon;
  width: 25px !important;
  height: 25px !important;
  background-size: contain;
  margin: 0 0 0 0 !important;
  padding: 0 !important;
  cursor: pointer;
  border-radius: 0 !important;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    margin-right: 22px !important
  }
}

.scaleIcon {
  @extend %scaleIcon;
  width: 25px !important;
  height: 25px !important;
  background-size: contain;
  margin: 0 0 0 0 !important;
  padding: 0 !important;
  cursor: pointer;
  border-radius: 0 !important;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    margin-right: 22px !important;
  }
}

.pushpinIcon {
  transform: rotate(35deg) !important;
}

.materialButtonColor {
  height: 50px;
  width: 100% !important;
  border-radius: 5px;
  background-color: #F57B20 !important;
  font-size: 16px !important;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  font-family: 'Lato', sans-serif;
  padding: 0 !important;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    margin-bottom: 22px !important;
    font-size: 12px !important;
    height: 35px !important;
  }
}

.titleRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
  margin-top: 2px;

  .titleNoMargin {
    width: auto !important;
    margin: 0 0 0 8px !important;
    font-weight: bold !important;
    font-size: 16px !important;
    line-height: 17px !important;
  }
}

.actsHaveDifference {
  cursor: pointer;
  width: 35px;
  height: 35px;
  min-width: 35px;
  min-height: 35px;
  @extend %actsHaveDifference
}

.actsNotRecognized {
  cursor: pointer;
  width: 35px;
  height: 35px;
  min-width: 35px;
  min-height: 35px;
  @extend %actsNotRecognized
}

.waitingForPair {
  cursor: pointer;
  width: 35px;
  height: 35px;
  min-width: 36px;
  min-height: 36px;
  @extend %waitingForPair
}

.actsMatch {
  cursor: pointer;
  width: 35px;
  height: 35px;
  min-width: 36px;
  min-height: 36px;
  @extend %actsHasMatch
}

.actsRejected {
  cursor: pointer;
  width: 35px;
  height: 35px;
  min-width: 36px;
  min-height: 36px;
  @extend %actsRejected
}

.actPassed {
  cursor: pointer;
  width: 35px;
  height: 35px;
  min-width: 36px;
  min-height: 36px;
  @extend %actPassed
}

.titleContainer {
  width: 100%;
  background: $commonBackground;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.12)!important;
}

.materialButtonHelp {
  height: 50px;
  width: 100% !important;
  min-width: 170px !important;
  border-radius: 5px;
  background-color: #1C3F95 !important;
  font-size: 16px !important;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
  align-items: center !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    margin-bottom: 22px;
    font-size: 12px !important;
    height: 35px;
  }
}

.commentsContainerItemHigh {
  height: 52px;
  min-height: 52px !important;
}

.commentsContainerItemLow {
  height: calc(40px - 7px);
}
