@import "src/constants";

.pdfFile {
  display: flex;
  flex-direction: column;

  .documentButtonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .documentButton {
      margin: 16px;
      cursor: pointer;
    }

  }

  .pageContainer {
    object-fit: contain;
    position: relative;

    > img {
      object-fit: contain;
      font-family: 'object-fit: contain;'
    }
  }

  .polyLine {
    position: absolute;
    border: 1px solid rgba(255, 0, 0, 0.56);
    cursor: pointer;
  }
  .polyLine:hover {
    border: 1px solid red;
  }

  .fileName {
    text-align: center;
    font-size: 15px;
    font-family: "Open Sans", "SansSerif", sans-serif;
  }

}

.selectedItem {
  border: 3.5px solid red !important;
  box-shadow: 0px 0px 8px 0px red !important;
}

.selectedItem:hover {
}

.rotateHorizontal {
  width: 100% !important;
  height: auto;
}

.rotateVertical {
  width: 820px !important;
  height: 820px !important;
}

.rotate90 {
  transform: rotate(90deg) !important;
}

.rotate180 {
  transform: rotate(180deg);
}

.rotate270 {
  transform: rotate(270deg);
}

.rotateMinus90 {
  transform: rotate(-90deg);
}

.rotateMinus180 {
  transform: rotate(-180deg);
}

.rotateMinus270 {
  transform: rotate(-270deg);
}
