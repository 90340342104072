@import "src/constants";

.popupBack {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;

  .dropzoneContainerFull {
    width: 100vw!important;
    height: 100vh !important;
    z-index: 1111111;
    background-color: transparent;
    position: fixed;
  }

  .popupContainer {
    z-index: 1;
    width: 55%;
    height: 55%;
    min-width: 500px;
    min-height: 400px;
    border-radius: 10px;
    border: 5px dashed #FFFFFF;
    background-color: transparent;

    .dropZone {
      width: 100%;
      height: 100%;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;

      .dropZoneTitle {
        font-family: 'Lato', "Open Sans", "SansSerif", sans-serif;
        font-size: 24px;
        font-weight: 600;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: left;
        text-transform: uppercase;
        color: #FFFFFF;
      }

    }
  }
}