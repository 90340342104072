@import "src/constants";

a {
  color: black;
  text-decoration: none;
}

.container {
  width: calc(100% - 12px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: $navigationBarHeight;
  background-color: $commonBackground;
  color: #FFF;
  border-bottom: 2px solid #FFFFFF;
  margin: 0 6px;

  .leftContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 2.5px;
    gap: 16px;

    .iconContainer {
      display: flex;
      flex-direction: column;
      zoom: 0.9;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        margin-right: 16px !important;
      }

      .title {
        font-family: 'Lato', sans-serif;
        font-size: 11px;
        line-height: 11px;
        font-weight: 500;
        color: gray;
        text-align: center;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        letter-spacing: 2.5px;
      }
    }
  }

  .topPanelRight {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 40px;

    .row {
      .rowItem {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;

        > a:first-child {
          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            margin-right: 5px;
          }
        }

        .textLink {
          font-family: 'Lato', sans-serif;
          font-size: 18px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0em;
          text-align: left;
          color: black;
          text-decoration: none;
          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            margin-left: 5px;
          }
        }

        .text {
          color: black;
          font-family: 'Lato', sans-serif;
          font-size: 15px;
          font-weight: 400;
          line-height: 15px;
          letter-spacing: 0em;
          text-align: right;
          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            margin-right: 5px;
          }
        }

        .textBalance {
          color: $colorBalance;
          font-family: 'Lato', sans-serif;
          font-size: 14px;
          font-weight: 800;
          line-height: 15px;
          letter-spacing: 0.05em;
          text-align: right;
        }

      }

    }

    .rowBalance {
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        margin-top: 5px;
      }
    }

    .buttonAction {
      width: 125px !important;
      font-family: 'Lato', sans-serif;
      font-size: 15px !important;
      font-weight: 500 !important;
      line-height: 16px !important;
      letter-spacing: 0em !important;
      cursor: pointer;
      height: 35px;
      background-color: $correctThemeColor;
      border-radius: 5px;
      border-width: 0;
      border-color: transparent;
      text-align: center;
      text-transform: none;
      color: white;
    }

    .buttonCancel {
      font-family: 'Lato', sans-serif;
      font-size: 15px !important;
      font-weight: 500 !important;
      line-height: 16px !important;
      letter-spacing: 0em !important;
      text-transform: none;
      cursor: pointer;
      width: 125px !important;
      height: 35px;
      background-color: transparent !important;
      border: 1px solid  $correctThemeColor !important;
      text-align: center;
      color: #000;
      border-radius: 5px;
    }


    .itemColumn {
      display: flex;
      flex-direction: column;
      gap: 5px;
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        margin-left: 60px;
      }
    }

    .topPanelRightAvatarCont {
      display: flex;
      align-items: center;
      color: $correctThemeColor;
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        margin-bottom: 5px;
      }
    }

    .topPanelRightAvatar {
      cursor: pointer;
      color: $correctThemeColor;
      margin-right: 10px;
    }

    .topPanelRightAvatarUserName {
      color: $correctThemeColor;
      cursor: pointer;
      font-family: 'Lato', sans-serif;
      font-size: 20px;
      font-weight: 800;
      line-height: 24px;
      letter-spacing: 0.05em;
      text-align: left;

    }

    .topPanelRightAvatarMenu {
      z-index: 1100;
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        margin-top: 5px;
      }
    }

  }

  .logo {
    width: 180px;
    height: 29px;
    background: no-repeat url("correctSvgFigmaLogo.svg");
    background-size: contain;
  }

  .link {
    font-family: 'Lato', sans-serif;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 19px !important;
    letter-spacing: 0em !important;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    width: 180px !important;
    height: 35px !important;
    background-color: $correctThemeColor !important;
    color: #FFFFFF !important;
    text-decoration: none;
    text-transform: none !important;
    outline: none;
    border: none;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      margin-left: 26px !important;
    }
  }

  .generateProtocol {
    display: flex !important;
    justify-content: flex-start !important;
    font-family: 'Lato', sans-serif;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    letter-spacing: 0em !important;
    text-align: left;
    align-items: center;
    border-radius: 5px;
    width: 300px !important;
    height: 35px !important;
    gap: 8px;
    background-color: transparent !important;
    border: 2px solid $correctThemeColor !important;
    padding: 6px 5px !important;
    color: #000 !important;
    text-decoration: none;
    text-transform: none !important;
    outline: none;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      margin-left: 16px;
    }

    > div:first-child {
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        margin-right: 8px;
      }
    }
  }

  .downloadCliche {
    display: flex !important;
    justify-content: flex-start !important;
    font-family: 'Lato', sans-serif;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    letter-spacing: 0em !important;
    text-align: left;
    align-items: center;
    border-radius: 5px;
    width: 171px !important;
    height: 50px !important;
    gap: 2px;
    background-color: transparent !important;
    border: 2px solid $correctThemeColor !important;
    padding: 6px 0px !important;
    color: #000 !important;
    text-decoration: none;
    text-transform: none !important;
    outline: none;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      margin-left: 16px;
    }

    > div:first-child {
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        margin-right: 2px;
      }
    }
  }

  .linkActive {
    opacity: 1 !important;
  }

  .arrowDown {
    margin-left: 3px;
    cursor: pointer;
    width: 17px;
    height: 17px;
    background: no-repeat url("arrowDown.svg");
  }

  .infoIcon {
    cursor: pointer;
    width: 18px;
    height: 18px;
    background: no-repeat url("info.svg");
    background-size: contain;
    margin-right: 5px;
  }

}

.actsHaveDifference {
  cursor: pointer;
  width: 35px;
  height: 35px;
  @extend %actsHaveDifference
}
.actsNotRecognized {
  cursor: pointer;
  width: 35px;
  height: 35px;
  @extend %actsNotRecognized
}

.waitingForPair {
  cursor: pointer;
  width: 35px;
  height: 35px;
  @extend %waitingForPair
}

.disabled {
  @extend %disabled;
}
