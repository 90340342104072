@import "src/constants";
.table {
  box-sizing: border-box;
  overflow: hidden;
  height: 100%;
  padding-left: 6px;
  padding-right: 6px;
  margin-top: 16px;
}

.monitoringPageContainer {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.react-daterange-picker__button{
  display: none !important;
}

.react-daterange-picker__wrapper{
  background-color: #0081ff !important;
}

.calendar{
  .wrapper {
  }
}

.iconRow {
  width: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.react-daterange-picker__wrapper {

}

.processesStatus {
  text-align: center;
  color: white;
  border: none;
  outline: none;
  display: inline-block;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  vertical-align: baseline;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  cursor: pointer;
}

.positiveStatus {
  background-color: #19bc9c;
}

.negativeStatus {
  background-color: #CC1F16;
}

.clearFilter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: $colorBalance !important;
  cursor: pointer;
}


.filterElement {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    margin-right: 16px;
  }

  .form-control {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      margin-right: 16px;
    }
  }

  .listItemIcon {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      margin-right: 8px;
    }
  }
}

.filtersContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 6px 0 6px;

  .topPart {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      margin-bottom: 16px;
    }

    .bottomPart {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 16px;

      .customSelect {
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          margin-right: 16px;
        }
      }

      .statuses {
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          margin-top: 20px;
        }

        .menuItem {
          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            margin-right: 16px;
          }

          :last-child {
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
              margin-right: 0 !important;
            }
          }
        }
      }
    }

    .rightPart {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

  }

  .bottomPart {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }

  .statusElement {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    height: 35px;
    width: 125px;
    border-radius: 5px;
    background-color: #FFFFFF;
    font-family: Lato, serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: #0000008C;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      margin-right: 15px !important
    }

    > span {
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        margin-right: 5px;
      }
    }
  }

  .filterIcon {
    height: 41px;
    max-height: 50px;
    border-radius: 50%;
    margin-top: 10px;
  }

  .searchIcon {
    cursor: pointer;
    width: 25px;
    height: 25px;
    background: no-repeat url("search.svg");
  }

  .processing {
    cursor: pointer;
    width: 35px;
    height: 35px;
    background: no-repeat url("processing.svg");
  }

  .processError {
    cursor: pointer;
    width: 35px;
    height: 35px;
    @extend %actsNotRecognized;
  }

  .twoDocsErrorStatus {
    cursor: pointer;
    width: 35px !important;
    height: 35px !important;
    @extend %actsHaveDifference;
  }

  .deletedDoc {
    cursor: pointer;
    width: 35px;
    height: 35px;
    background: no-repeat url("deletedDoc.svg");
  }

}

.buttonAction {
  @extend %buttonAction;
  width: 180px;
  height: 40px;
  margin-right: 10px !important;
}

.disabled {
  @extend %disabled;
}

.description {
  max-width: 300px;
  white-space: normal;
  margin-right: 8px;
}
