@import 'src/constants';

.popupBackground {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9000;
  background-color: rgba(0, 0, 0, 0.4);
  overflow: auto;

  .modalContainer {
    border-radius: 16px;
    width: 50%;
    height: 80%;
    padding: 36px 36px 0px 36px;
    background-color: white;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    .photoListContainer {
      width: 100%;
      height: 100%;
      overflow-y: auto;

      .loading {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .documentImage {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        object-fit: contain;
        font-family: 'object-fit: contain;'
      }


    }

    .buttonAction {
      width: 150px;
      height: 40px;
      margin-top: 16px;
      margin-bottom: 16px;
      @extend %buttonAction;
    }

    .disabled {
      @extend %disabled;
    }

  }

}

.closeIcon {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
  background-size: contain;
  @extend %closeIcon;
}

.loading {
  display: flex;
  width: 500px;
  height: 800px;
  justify-content: center;
  align-items: center;
}

.pdfFilesContainerPaper {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0;
  height: 100%;
  overflow-y: hidden;
}

.pdfFilesContainer {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  width: 100% !important;
  height: 100%;
  overflow-y: auto !important;
  gap: 16px;

  @media (max-width: 1300px) and (orientation: landscape) {
    height: 100%;
    overflow-x: auto;
  }

}

.uploadButton {
  font-family: 'Lato', sans-serif;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 19px !important;
  letter-spacing: 0em !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: 220px !important;
  height: 50px !important;
  background: $correctThemeColor !important;
  color: #FFFFFF !important;
  text-decoration: none;
  text-transform: none !important;
  text-wrap: none;
  outline: none;
  border: none;
}

.cancelButton {
  font-family: 'Lato', sans-serif;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 19px !important;
  letter-spacing: 0em !important;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: 222px !important;
  height: 50px !important;
  border: 1px solid $correctThemeColor !important;
  background-color: transparent !important;
  color: #000 !important;
  text-decoration: none;
  text-transform: none !important;
  outline: none;
}

.pdfFile {
  width: 490px;
  height: 820px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow-y: auto;
  position: relative;

  .documentButtonsContainer {
    width: calc(100% - 100px);
    max-width: 390px;

    margin-left: 60px;
    margin-right: 40px;
    margin-bottom: 48px;

    .top {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .bottom {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &Left{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 22px;
    }

    &Right {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 16px;

      .pageNumberTitle {
        font-family: 'Lato', sans-serif;
        font-size: 20px;
        font-weight: 300;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
      }
    }

    .documentButton {
      margin: 16px;
      cursor: pointer;
    }
  }

  .invertedPageContainer {
    width: 485px !important;
    height: 490px !important;
    margin-top: 50px;
  }

  .pageContainer {
    width: 490px;
    height: 650px;
    overflow: auto;
  }

  .container {
    display: flex;
    overflow-y: auto;
    width: 490px;
    height: 650px;
  }

  .containerRotate {
    height: 580px !important;
  }

  .containerCenter {
    justify-content: center;
  }

  .fileName {
    margin-top: 20px;
    margin-bottom: 40px;
    text-align: center;
    font-size: 15px;
    font-family: "Open Sans", "SansSerif", sans-serif;
    color: black;
  }

}

.rotateHorizontal {
  width: 580px;
  height: 820px;
}

.rotate90 {
  transform: rotate(90deg) !important;
}

.rotate180 {
  transform: rotate(180deg) !important;
}

.rotate270 {
  transform: rotate(270deg) !important;
}

.rotateMinus90 {
  transform: rotate(-90deg) !important;
}

.rotateMinus180 {
  transform: rotate(-180deg) !important;
}

.rotateMinus270 {
  transform: rotate(-270deg);
}

.inputDocuments {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  overflow: auto;
  box-sizing: border-box;
  padding-left: 8px;
  padding-top: 8px;
  height: 280px;
  gap: 6px;

  .selectedItem {
    border: 3px solid $correctThemeColor !important;
    box-shadow: 0px 0px 8px 0px $correctThemeColor !important;
  }

  &Item {
    width: 140px !important;
    border-radius: 5px;
    color: white;
    outline: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 4px 4px !important;
    text-align: center !important;
    text-decoration: none;
    padding-bottom: 15px !important;
    height: 245px !important;
    border: 1px solid $correctThemeColor;

    .documentContainer {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .filePreview {
      width: 135px;
      height: 180px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &Description {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-decoration: none !important;
      color: #000000;
      font-family: 'Lato', sans-serif;
      font-size: 14px;
      font-weight: 300;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: center;

      margin-top: 5px;
      overflow-wrap: normal;
      height: 14px !important;
      word-break: break-word;
    }
  }
}

.rotateLeftIcon {
  @extend %rotateLeftIcon;
  width: 30px !important;
  height: 30px !important;
  margin: 0 0 0 0 !important;
  padding: 0 !important;
  cursor: pointer;
  border-radius: 0 !important;
}

.rotateRightIcon {
  @extend %rotateRightIcon;
  width: 30px !important;
  height: 30px !important;
  margin: 0 0 0 0 !important;
  padding: 0 !important;
  cursor: pointer;
  border-radius: 0 !important;
}

.zoomInIcon {
  @extend %zoomInIcon;
  width: 30px !important;
  height: 30px !important;
  margin: 0 0 0 0 !important;
  padding: 0 !important;
  cursor: pointer;
  border-radius: 0 !important;
}

.zoomOutIcon {
  @extend %zoomOutIcon;
  width: 30px !important;
  height: 30px !important;
  margin: 0 0 0 0 !important;
  padding: 0 !important;
  cursor: pointer;
  border-radius: 0 !important;
}

.scaleIcon {
  @extend %scaleIcon;
  width: 30px !important;
  height: 30px !important;
  margin: 0 0 0 0 !important;
  padding: 0 !important;
  cursor: pointer;
  border-radius: 0 !important;
}

.arrowLeft {
  @extend %arrowLeft;
  width: 15px !important;
  height: 15px !important;
  margin: 0 0 0 0 !important;
  padding: 0 !important;
  cursor: pointer;
  border-radius: 0 !important;
}
.arrowRight {
  @extend %arrowRight;
  width: 15px !important;
  height: 15px !important;
  margin: 0 0 0 0 !important;
  padding: 0 !important;
  cursor: pointer;
  border-radius: 0 !important;
}
