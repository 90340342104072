@import "src/constants";

.popupBack {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.4);
  box-sizing: border-box;

  .popupContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: calc(50% - 250px);
    box-sizing: border-box;
    width: 500px;
    max-width: calc(100% - 20px);
    background-color: white;
    border-radius: 10px;
    padding-top: 30px;
    padding-bottom: 10px;
    margin-bottom: 10px;

    .messageContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 10px 15px 10px;
      flex-grow: 1;

      .message {
        font: normal normal 17px/20px "Roboto", sans-serif;
        text-align: center;
        color: #8697a8;
      }

    }

    .buttonContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 5px;
      width: 80%;
      gap: 16px;
      text-transform: none;
      color: black;

      .buttonAction {
        width: 100px !important;
        font-family: 'Lato', sans-serif;
        font-size: 16px !important;
        font-weight: 500 !important;
        line-height: 19px !important;
        letter-spacing: 0em !important;
        cursor: pointer;
        height: 40px;
        background-color: $correctThemeColor;
        border-radius: 5px;
        text-transform: none;
        text-align: center;
        color: white;
      }

      .buttonCancel {
        font-family: 'Lato', sans-serif;
        font-size: 16px !important;
        font-weight: 500 !important;
        line-height: 19px !important;
        letter-spacing: 0em !important;
        cursor: pointer;
        width: 100px;
        height: 40px;
        background-color: transparent;
        border: 1px solid  $correctThemeColor;
        text-align: center;
        color: #000;
        border-radius: 5px;
        text-transform: none;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          margin-left: 16px;
        }
      }
    }
  }
}

.disabled {
  @extend %disabled;
}
