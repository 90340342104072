$commonBlue: #3066F0;
$commonBackground: #F2F7FA;
$commentBackground: #f9f9f9;
$grayBackground: #515151;
$correctThemeColor: #F57B20;
$colorBalance: #1C3F95;
$selectedDiffColor: rgba(245, 123, 32, 0.6);
$footerHeight: 25px;
$navigationBarHeight: 45px;

%disabled {
  opacity: 0.5;
  filter: grayscale(1);
}

%selected {
  box-shadow: 0px 0px 3px 3px $commonBlue !important;
}

%rotateLeftIcon {
  background: no-repeat url("rotateLeft.svg");
}

%rotateRightIcon {
  background: no-repeat url("rotateRight.svg");
}

%zoomInIcon {
  background: no-repeat url("zoomPlus.svg");
}

%zoomOutIcon {
  background: no-repeat url("zoomMinus.svg");
}

%scaleIcon {
  background: no-repeat url("scale.svg");
}

%arrowLeft {
  background: no-repeat url("arrowLeft.svg");
}

%arrowRight {
  background: no-repeat url("arrowRight.svg");
}

%actsHaveDifference {
  background: no-repeat url("twoDocsErrorStatus.svg");
}

%actsHasMatch {
  background: no-repeat url("twoDocOkStatus.svg");
}

%actsRejected {
  background: no-repeat url("processFinishErrorStatus.svg");
}

%actPassed {
  background: no-repeat url("processFinishokStatus.svg");
}

%waitingForPair {
  background: no-repeat url("waitingForPair.svg");
}

%actsNotRecognized {
  background: no-repeat url("processError.svg");
}

%buttonAction {
  font-family: 'Lato', sans-serif;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 19px !important;
  letter-spacing: 0em !important;
  cursor: pointer;
  background-color: $correctThemeColor !important;
  border-radius: 5px;
  border-width: 0;
  border-color: transparent;
  text-align: center;
  text-transform: none !important;
  color: white !important;
}

%closeIcon {
  border-radius: 0 !important;
  position: absolute;
  cursor: pointer;
  background: no-repeat url("close.svg");
  background-size: contain;
  object-fit: contain;
}
